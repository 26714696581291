import { ArrowRight, Bath, Bed, Maximize } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { FloorPlan, Property } from '../../clients/vercasa';
import { formatCurrency } from '../../utils/currencyFormatter';
import { getAverageOccupancyFromFloorPlan, getHighestAverageDailyRateFromFloorPlan, getHighestProfitFromFloorPlan, getPropertyStats } from '../../utils/propertyUtils';


interface FloorPlansProps {
    property: Property;
    onSeeROI: (floorPlan: FloorPlan) => void;
}

interface FloorPlanCardProps {
    floorPlan: FloorPlan;
    mostExpensivePrice: number;
    highestOverallProfit: number;
    highestOverallAverageDailyRate: number;
    onSeeROI: (floorPlan: FloorPlan) => void;
    showROIButton?: boolean;
}

const FloorPlanCard: React.FC<FloorPlanCardProps> = ({
    floorPlan,
    mostExpensivePrice,
    highestOverallProfit,
    highestOverallAverageDailyRate,
    onSeeROI,
    showROIButton
}) => {
    const highestProfit = getHighestProfitFromFloorPlan(floorPlan);
    const highestAverageDailyRate = getHighestAverageDailyRateFromFloorPlan(floorPlan);
    const averageOccupancy = getAverageOccupancyFromFloorPlan(floorPlan);

    const rentPercentage = (floorPlan.listedPrice / mostExpensivePrice) * 100;
    const adrPercentage = (highestAverageDailyRate / highestOverallAverageDailyRate) * 100;
    const profitPercentage = (highestProfit / highestOverallProfit) * 100;

    return (
        <div className="flex flex-col md:flex-row gap-6 p-6 bg-white rounded-lg shadow-md dark:bg-boxdark">
            <div className="flex-1">
                <h3 className="text-xl font-semibold mb-4">
                    {floorPlan.bedrooms} Bed / {floorPlan.bathrooms} Bath
                </h3>
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center gap-2">
                        <Bed className="text-primary" size={20} />
                        <span>{floorPlan.bedrooms} Bedrooms</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <Bath className="text-primary" size={20} />
                        <span>{floorPlan.bathrooms} Bathrooms</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <Maximize className="text-primary" size={20} />
                        <span>{floorPlan.sqft} sqft</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <ArrowRight className="text-primary" size={20} />
                        <span>{floorPlan.availableNow ? 'Available Now' : 'Not Available'}</span>
                    </div>

                    {showROIButton && (
                        <div className="mt-4">
                            <button
                                onClick={() => onSeeROI(floorPlan)}
                                className="inline-flex items-center justify-center rounded-md border border-primary py-2 px-4 text-center font-medium text-primary hover:bg-opacity-90 lg:px-6 xl:px-7 dark:bg-primary dark:text-white"
                            >
                                See Return on Investment
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex-1">
                <div className="mb-4">
                    <div className="flex items-center justify-between mb-3">
                        <span className="font-medium text-black dark:text-white">Monthly Rent</span>
                        <span className="text-sm font-medium">{formatCurrency(floorPlan.listedPrice)}</span>
                    </div>
                    <div className="relative h-1.5 w-full rounded-full bg-stroke dark:bg-strokedark">
                        <span className="absolute left-0 block h-1.5 rounded-full bg-warning" style={{ width: `${rentPercentage}%` }}></span>
                    </div>
                </div>
                <div className="mb-4">
                    <div className="flex items-center justify-between mb-3">
                        <span className="font-medium text-black dark:text-white">Occupancy</span>
                        <span className="text-sm font-medium">{averageOccupancy.toFixed(2)}%</span>
                    </div>
                    <div className="relative h-1.5 w-full rounded-full bg-stroke dark:bg-strokedark">
                        <span className="absolute left-0 block h-1.5 rounded-full bg-secondary" style={{ width: `${averageOccupancy}%` }}></span>
                    </div>
                </div>
                <div className="mb-4">
                    <div className="flex items-center justify-between mb-3">
                        <span className="font-medium text-black dark:text-white">Avg. Daily Rate</span>
                        <span className="text-sm font-medium">{formatCurrency(highestAverageDailyRate)}</span>
                    </div>
                    <div className="relative h-1.5 w-full rounded-full bg-stroke dark:bg-strokedark">
                        <span className="absolute left-0 block h-1.5 rounded-full bg-primary" style={{ width: `${adrPercentage}%` }}></span>
                    </div>
                </div>
                <div>
                    <div className="flex items-center justify-between mb-3">
                        <span className="font-medium text-black dark:text-white">Profit</span>
                        <span className="text-sm font-medium">{formatCurrency(highestProfit)}</span>
                    </div>
                    <div className="relative h-1.5 w-full rounded-full bg-stroke dark:bg-strokedark">
                        <span className="absolute left-0 block h-1.5 rounded-full bg-success" style={{ width: `${profitPercentage}%` }}></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const FloorPlans: React.FC<FloorPlansProps> = ({ property, onSeeROI }) => {
    const [showAllFloorPlans, setShowAllFloorPlans] = useState(false);

    const {
        sortedFloorPlans,
        mostExpensivePrice,
        highestOverallProfit,
        highestOverallAverageDailyRate
    } = useMemo(() => getPropertyStats(property), [property]);

    const isMultiUnit = property.floorPlans.length > 1;
    const displayedFloorPlans = showAllFloorPlans ? sortedFloorPlans : sortedFloorPlans.slice(0, 2);

    return (
        <div className="mb-5 px-6 py-6 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Floor Plans
            </h4>
            <div className="space-y-6">
                {displayedFloorPlans.map((plan) => (
                    <FloorPlanCard
                        key={plan.id}
                        floorPlan={plan}
                        mostExpensivePrice={mostExpensivePrice}
                        highestOverallProfit={highestOverallProfit}
                        highestOverallAverageDailyRate={highestOverallAverageDailyRate}
                        onSeeROI={onSeeROI}
                        showROIButton={isMultiUnit}
                    />
                ))}
            </div>
            {sortedFloorPlans.length > 2 && (
                <div className="mt-6 mb-6 text-center">
                    <button
                        onClick={() => setShowAllFloorPlans(!showAllFloorPlans)}
                        className="inline-flex items-center justify-center rounded-md border border-primary py-2 px-4 text-center font-medium text-primary hover:bg-opacity-90 lg:px-6 xl:px-7 dark:bg-primary dark:text-white"
                    >
                        {showAllFloorPlans ? 'Show Less' : 'See All Floor Plans'}
                    </button>
                </div>
            )}
        </div>
    );
};

export default FloorPlans;