/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Office hours with day, start time, and end time
 * @export
 * @interface PropertyOfficeHours
 */
export interface PropertyOfficeHours {
    /**
     * The day of the week
     * @type {string}
     * @memberof PropertyOfficeHours
     */
    day: PropertyOfficeHoursDayEnum;
    /**
     * Start time of office hours
     * @type {Date}
     * @memberof PropertyOfficeHours
     */
    start: Date;
    /**
     * End time of office hours
     * @type {Date}
     * @memberof PropertyOfficeHours
     */
    end: Date;
}


/**
 * @export
 */
export const PropertyOfficeHoursDayEnum = {
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday'
} as const;
export type PropertyOfficeHoursDayEnum = typeof PropertyOfficeHoursDayEnum[keyof typeof PropertyOfficeHoursDayEnum];


/**
 * Check if a given object implements the PropertyOfficeHours interface.
 */
export function instanceOfPropertyOfficeHours(value: object): value is PropertyOfficeHours {
    if (!('day' in value) || value['day'] === undefined) return false;
    if (!('start' in value) || value['start'] === undefined) return false;
    if (!('end' in value) || value['end'] === undefined) return false;
    return true;
}

export function PropertyOfficeHoursFromJSON(json: any): PropertyOfficeHours {
    return PropertyOfficeHoursFromJSONTyped(json, false);
}

export function PropertyOfficeHoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyOfficeHours {
    if (json == null) {
        return json;
    }
    return {
        
        'day': json['Day'],
        'start': (new Date(json['Start'])),
        'end': (new Date(json['End'])),
    };
}

export function PropertyOfficeHoursToJSON(value?: PropertyOfficeHours | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Day': value['day'],
        'Start': ((value['start']).toISOString()),
        'End': ((value['end']).toISOString()),
    };
}

