import React, { createContext, useContext, useEffect, useState } from 'react';
import { AddressesApi, AuthApi, AuthTokens, Configuration, PropertiesApi, PropertyListsApi, UsersApi } from '../clients/vercasa';
import Loader from '../common/Loader';
import env from '../config/env';
import useLocalStorage from '../hooks/useLocalStorage';

interface ApiContextType {
  usersApi: UsersApi;
  propertiesApi: PropertiesApi;
  addressesApi: AddressesApi;
  authApi: AuthApi;
  propertyListsApi: PropertyListsApi
  tokens: AuthTokens | null;
  setTokens: (tokens: AuthTokens | null) => void;
}

const ApiContext = createContext<ApiContextType | undefined>(undefined);

export const ApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tokens, setTokens] = useLocalStorage<AuthTokens | null>('tokens', null);
  const [apis, setApis] = useState<ApiContextType | null>(null);

  useEffect(() => {
    const config = new Configuration({
      basePath: env.VERCASA_API_URL,
      accessToken: tokens?.accessToken,
    });

    setApis({
      usersApi: new UsersApi(config),
      propertiesApi: new PropertiesApi(config),
      addressesApi: new AddressesApi(config),
      authApi: new AuthApi(config),
      propertyListsApi: new PropertyListsApi(config),
      tokens,
      setTokens,
    });
  }, [tokens]);

  if (!apis) {
    return <Loader />;
  }

  return <ApiContext.Provider value={apis}>{children}</ApiContext.Provider>;
};


export const useApi = () => {
  const context = useContext(ApiContext);

  if (context === undefined) {
    throw new Error('useApi must be used within an ApiProvider');
  }

  return context;
};