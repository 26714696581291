// FilterDropdown.tsx
import { ChevronDown } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';

interface FilterDropdownProps {
  title: string;
  children: React.ReactNode;
  onApply: () => void;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({ title, children, onApply }) => {
  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !isOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setIsOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!isOpen || keyCode !== 27) return;
      setIsOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative mb-5.5">
      <button
        ref={trigger}
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center gap-2.5 rounded-md bg-primary py-3 px-5.5 font-medium text-white hover:bg-opacity-90"
      >
        {title}

        <ChevronDown className={`h-5 w-5 stroke-current text-white transition duration-200 ease-linear ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      {isOpen && (

        <div ref={dropdown} className="absolute z-10 mt-1 w-100 mt-2 rounded-md border border-stroke bg-white py-3 shadow-card dark:border-strokedark dark:bg-boxdark block">
          <div className="py-1">
            <div className="px-4 py-2">
              {children}
            </div>
            <div className="px-4 py-2">
              <button
                onClick={() => {
                  onApply();
                  setIsOpen(false);
                }}
                className="w-full px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;