import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PropertiesPropertyTypeEnum, Property } from '../clients/vercasa';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import SelectGroupThree from '../components/Forms/SelectGroup/SelectGroupThree';
import PageTitle from '../components/PageTitle';
import PropertyCard from '../components/Property/Card';
import FilterDropdown from '../components/Property/FilterDropDown';
import env from '../config/env';
import { useColorMode } from '../contexts/ColorModeContext';
import { useAddressAPI, userPropertiesAPI } from '../hooks/useVerCasaAPI';
import DefaultLayout from '../layout/DefaultLayout';



mapboxgl.accessToken = env.MAPBOX_ACCESS_TOKEN;

const stateCoordinates: { [key: string]: [number, number, number] } = {
    '': [-98.5795, 39.8283, 4],
    'AL': [-86.79113, 32.806671, 6],
    'AK': [-152.404419, 61.370716, 4],
    'AZ': [-111.431221, 33.729759, 6],
    'AR': [-92.373123, 34.969704, 6],
    'CA': [-119.681564, 36.116203, 5],
    'CO': [-105.311104, 39.059811, 6],
    'CT': [-72.755371, 41.597782, 7],
    'DE': [-75.507141, 39.318523, 7],
    'FL': [-81.686783, 27.664827, 6],
    'GA': [-83.643074, 32.157435, 6],
    'HI': [-157.498337, 21.094318, 6],
    'ID': [-114.478828, 44.240459, 5],
    'IL': [-88.986137, 40.349457, 6],
    'IN': [-86.258278, 39.849426, 6],
    'IA': [-93.210526, 42.011539, 6],
    'KS': [-96.726486, 38.5266, 6],
    'KY': [-84.670067, 37.668140, 6],
    'LA': [-91.867805, 31.169546, 6],
    'ME': [-69.381927, 44.693947, 6],
    'MD': [-76.802101, 39.063946, 7],
    'MA': [-71.530106, 42.230171, 7],
    'MI': [-84.536095, 43.326618, 6],
    'MN': [-93.900192, 45.694454, 6],
    'MS': [-89.678696, 32.741646, 6],
    'MO': [-92.288368, 38.456085, 6],
    'MT': [-110.454353, 46.921925, 5],
    'NE': [-98.268082, 41.125370, 6],
    'NV': [-117.055374, 38.313515, 5],
    'NH': [-71.563896, 43.452492, 7],
    'NJ': [-74.521011, 40.298904, 7],
    'NM': [-106.248482, 34.840515, 6],
    'NY': [-74.948051, 42.165726, 6],
    'NC': [-79.806419, 35.630066, 6],
    'ND': [-99.784012, 47.528912, 6],
    'OH': [-82.764915, 40.388783, 6],
    'OK': [-96.928917, 35.565342, 6],
    'OR': [-122.070938, 44.572021, 6],
    'PA': [-77.209755, 40.590752, 6],
    'RI': [-71.511780, 41.680893, 8],
    'SC': [-80.945007, 33.856892, 6],
    'SD': [-99.438828, 44.299782, 6],
    'TN': [-86.692345, 35.747845, 6],
    'TX': [-97.563461, 31.054487, 5],
    'UT': [-111.862434, 40.150032, 6],
    'VT': [-72.710686, 44.045876, 7],
    'VA': [-78.169968, 37.769337, 6],
    'WA': [-121.490494, 47.400902, 6],
    'WV': [-80.954453, 38.491226, 6],
    'WI': [-89.616508, 44.268543, 6],
    'WY': [-107.551114, 42.755966, 6]
};

const defaultFiltersState = () => {
    return {
        minBeds: 0,
        maxBeds: 5,
        minBaths: 0,
        maxBaths: 5,
        minProfit: 0,
        maxProfit: 1000000,
        city: '',
        propertyType: '',
    }
};


const Properties: React.FC = () => {

    const map = useRef<mapboxgl.Map | null>(null);
    const mapContainer = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const { colorMode } = useColorMode();
    const [currentPopup, setCurrentPopup] = useState<mapboxgl.Popup | null>(null);

    const { getProperties, favoriteProperty } = userPropertiesAPI();
    const { getStates } = useAddressAPI();


    const [stateOptions, setStateOptions] = useState<{ value: string, label: string }[]>([{ value: '', label: 'Please Select a State' }]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedState, setSelectedState] = useState<string | null>(searchParams.get('state_code') || '');

    const [loading, setLoading] = useState(false);

    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(parseInt(searchParams.get('limit')) || 26);
    const [offset, setOffset] = useState(parseInt(searchParams.get('offset')) || 0);
    const [hasMore, setHasMore] = useState(false);

    const [filters, setFilters] = useState({
        minBeds: parseInt(searchParams.get('min_beds')) || 0,
        maxBeds: parseInt(searchParams.get('max_beds')) || 5,
        minBaths: parseInt(searchParams.get('min_baths')) || 0,
        maxBaths: parseInt(searchParams.get('max_baths')) || 5,
        minProfit: parseInt(searchParams.get('min_profit')) || 0,
        maxProfit: parseInt(searchParams.get('max_profit')) || 1000000,
        city: searchParams.get('city') || '',
        propertyType: searchParams.get('property_type') || '',
    });

    const [propertiesMap, setPropertiesMap] = useState<{ [key: string]: Property }>({});
    const [markedProperties, setMarkedProperties] = useState<Set<string>>(new Set());

    useEffect(() => {
        fetchStates();

        if (mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/standard?optimize=true',
                center: [-98.5795, 39.8283], // Center of USA
                zoom: 3
            });



            map.current.once('style.load', setMapConfigProperties)
        }

        return () => {
            if (map.current) {
                map.current?.remove();
            }

        };
    }, []);

    useEffect(() => {
        if (map.current) {
            map.current.on('click', removeCurrentPopup);
            map.current.on('zoom', removeCurrentPopup);
            map.current.on('drag', removeCurrentPopup);
        }

        return () => {
            if (map.current) {
                map.current.off('click', removeCurrentPopup);
                map.current.off('zoom', removeCurrentPopup);
                map.current.off('drag', removeCurrentPopup);
            }
        }
    }, [currentPopup]);


    useEffect(() => {
        if (map.current) {

            if (map.current.isStyleLoaded()) {
                setMapConfigProperties();
            }

            return () => {
                map.current.off('style.load', setMapConfigProperties);
            };
        }
    }, [colorMode]);


    useEffect(() => {
        if (selectedState) {
            setLimit(25);
            setOffset(0);
            setCount(0);
            setHasMore(false);
            setPropertiesMap({});
            updatePropertyMarkers();
            setMarkedProperties(new Set());
            setFilters(defaultFiltersState());
            zoomToState(selectedState);
        }

        if (!selectedState) {
            setPropertiesMap({});
            setHasMore(false);
            updatePropertyMarkers();
            setMarkedProperties(new Set());
            setFilters(defaultFiltersState());
            zoomToState(selectedState);
            setSearchParams({});
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedState) {
            fetchProperties();
        }
    }, [offset, selectedState]);

    useEffect(() => {
        if (map.current) {
            updatePropertyMarkers();
        }
    }, [propertiesMap])

    useEffect(() => {
        if (map.current) {
            updateMapCameraBounds();
        }
    }, [markedProperties]);


    const handleLoadMore = () => {
        if (offset + limit < count) {
            setOffset(prevOffset => prevOffset + limit);
        }
    };

    const handleFilterSubmit = () => {
        setOffset(0);
        setLimit(26);

        setPropertiesMap({});
        fetchProperties();
    };

    const handleFilterChange = (name: string, value: number | string) => {
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    const handleViewDetails = (propertyId: string) => {
        navigate(`/properties/${propertyId}`);
    }

    const handlePropertyClick = (property: Property) => {
        if (map.current) {
            map.current.flyTo({
                center: [property.address.longitude, property.address.latitude],
                zoom: 15,
                essential: true
            });
        }
    };

    const handleFavoriteClick = async (id: string) => {
        try {
            await favoriteProperty(id);
        } catch (error) {
            console.error('Error toggling favorite:', error);
            throw new Error("Error toggling favorite");
        }
    };

    const setMapConfigProperties = () => {
        map.current.addControl(new mapboxgl.NavigationControl());
        map.current.setConfigProperty('basemap', 'lightPreset', colorMode === 'dark' ? 'dusk' : 'day');
        map.current.setConfigProperty('basemap', 'showPlaceLabels', true);
        map.current.setConfigProperty('basemap', 'showPointOfInterestLabels', true);
        map.current.setConfigProperty('basemap', 'showRoadLabels', true);
    }


    const zoomToState = (stateCode: string) => {
        if (!map.current) return;

        const [lng, lat, zoom] = stateCoordinates[stateCode] || [-98.5795, 39.8283, 3];
        map.current.flyTo({
            center: [lng, lat],
            zoom: zoom,
            essential: true
        });
    };

    const fetchStates = async () => {
        try {
            const response = await getStates();
            const options = response.map(state => ({
                value: state.code,
                label: state.name
            }));

            options.unshift({ value: '', label: 'Please Select a State' });
            setStateOptions(options);
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };

    const getHighestRentalEstimate = (property: Property): number => {
        return Math.max(...property.floorPlans.flatMap(fp =>
            fp.rentalEstimates.map(re => re.profit)
        ));
    };

    const getProfitColor = (profit: number): string => {
        if (profit > 12000) return 'success'; // Green
        if (profit > 6000) return 'warning'; // Yellow
        return 'danger'; // Red
    };

    const fetchProperties = async () => {
        if (!map.current || !selectedState) return;

        setLoading(true);

        try {
            const res = await getProperties({
                limit: limit,
                offset: offset,
                minBeds: filters.minBeds,
                maxBeds: filters.maxBeds,
                minBaths: filters.minBaths,
                maxBaths: filters.maxBaths,
                minProfit: filters.minProfit,
                maxProfit: filters.maxProfit,
                city: filters.city,
                stateCode: selectedState,
                propertyType: filters.propertyType === '' ? undefined : filters.propertyType as PropertiesPropertyTypeEnum,
            });

            setPropertiesMap(prevProperties => {
                const newProperties = { ...prevProperties };
                res.data.forEach(property => {
                    newProperties[property.id] = property;
                });
                return newProperties;
            });

            setCount(res.count);
            setLimit(res.limit);
            setHasMore(res.offset + res.limit < res.count);
            setSearchParams({
                'limit': limit.toString(),
                'offset': offset.toString(),
                'min_beds': filters.minBeds.toString(),
                'max_beds': filters.maxBeds.toString(),
                'min_baths': filters.minBaths.toString(),
                'max_baths': filters.maxBaths.toString(),
                'min_profit': filters.minProfit.toString(),
                'max_profit': filters.maxProfit.toString(),
                'property_type': filters.propertyType,
                'city': filters.city,
                'state_code': selectedState
            })

        } catch (error) {
            console.error('Error fetching properties:', error);
        } finally {
            setLoading(false);
        }
    };

    const updateMapCameraBounds = () => {
        if (!map.current) return;

        const bounds = new mapboxgl.LngLatBounds();

        Object.values(propertiesMap).forEach(property => {
            bounds.extend([property.address.longitude, property.address.latitude]);
            map.current.fitBounds(bounds, { padding: 50 });
        });
    }

    const removeCurrentPopup = () => {
        if (currentPopup) {
            currentPopup.remove();
            setCurrentPopup(null);
        }
    }

    const updatePropertyMarkers = () => {
        if (!map.current) return;

        if (JSON.stringify(propertiesMap) === '{}') {
            // Remove existing markers
            const existingMarkers = document.getElementsByClassName('property-marker');
            while (existingMarkers[0]) {
                existingMarkers[0].parentNode?.removeChild(existingMarkers[0]);
            }

            return;
        };

        const markedProperties: Set<string> = new Set();

        // Add new markers
        Object.values(propertiesMap).forEach(property => {
            const highestProfit = getHighestRentalEstimate(property);
            const color = getProfitColor(highestProfit);


            const el = document.createElement('div');
            el.style.cursor = 'pointer';
            el.className = `property-marker bg-${color} rounded-lg`;
            el.innerHTML = `
              <div style="color: white; padding: 5px 10px; font-weight: bold;">
                $ ${highestProfit.toLocaleString()}
              </div>
            `;

            const marker = new mapboxgl.Marker(el)
                .setLngLat([property.address.longitude, property.address.latitude])
                .addTo(map.current!);

            const markerElement = marker.getElement();

            // Add event listeners
            markerElement.addEventListener('click', (e) => {
                e.stopPropagation(); // Prevent the map click event from firing

                const popupContent = createPopupContent(property);

                // Calculate available space above and below the marker
                const markerHeight = markerElement.clientHeight;
                const mapHeight = map.current!.getContainer().clientHeight;
                const markerPoint = map.current!.project([property.address.longitude, property.address.latitude]);
                const spaceAbove = markerPoint.y;
                const spaceBelow = mapHeight - markerPoint.y - markerHeight;

                // Determine popup placement
                const popupOffset: [number, number] = spaceBelow > spaceAbove ? [0, markerHeight] : [0, -markerHeight];

                // Create a popup
                const popup = new mapboxgl.Popup({
                    closeButton: false,
                    closeOnClick: false,
                    maxWidth: '300px',
                    offset: popupOffset,
                    anchor: spaceBelow > spaceAbove ? 'top' : 'bottom'
                })
                    .setLngLat([property.address.longitude, property.address.latitude])
                    .setDOMContent(popupContent)
                    .addTo(map.current!);

                setCurrentPopup(prevPopup => {
                    prevPopup?.remove();
                    return popup;
                });
            });

            markedProperties.add(property.id);
        });

        setMarkedProperties(prev => {
            return new Set([...prev, ...markedProperties]);
        });
    };

    const createPopupContent = (property: Property) => {
        const container = document.createElement('div');
        const root = createRoot(container);
        root.render(<PropertyCard property={property} onClick={() => { }} onViewDetails={handleViewDetails} onFavoriteToggle={handleFavoriteClick} />);
        return container;
    };

    return (
        <DefaultLayout>
            <Breadcrumb />
            <PageTitle title='Properties' />

            <div className="h-[calc(100vh-280px)]">

                <div className='h-full w-full'>

                    <div className="flex justify-between items-center">
                        <div className='w-3/5'>

                            {!selectedState &&
                                <div className="mb-4">
                                    <p className="text-gray-500">
                                        Select a state to view available properties for rent.
                                    </p>
                                </div>
                            }

                            <div className="flex space-x-4">

                                <div className="flex-1">
                                    <SelectGroupThree label='' selected={selectedState} setSelected={setSelectedState} options={stateOptions} />
                                </div>


                                <div className="flex flex-wrap space-x-4">

                                    {selectedState && (
                                        <>
                                            <FilterDropdown title="Beds & Baths" onApply={handleFilterSubmit}>
                                                <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">

                                                    <h3 className="font-medium text-black dark:text-white">Bedrooms</h3>
                                                    <span className="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span>

                                                    <div className="grid grid-cols-2 gap-4 mb-5">
                                                        <div>
                                                            <label htmlFor="minBeds" className="block mb-2">Min Beds</label>
                                                            <input
                                                                type="number"
                                                                id="minBeds"
                                                                name="minBeds"
                                                                value={filters.minBeds}
                                                                onChange={(e) => handleFilterChange('minBeds', parseInt(e.target.value))}
                                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="maxBeds" className="block mb-2">Max Beds</label>
                                                            <input
                                                                type="number"
                                                                id="maxBeds"
                                                                name="maxBeds"
                                                                value={filters.maxBeds}
                                                                onChange={(e) => handleFilterChange('maxBeds', parseInt(e.target.value))}
                                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                                                    <h3 className="font-medium text-black dark:text-white">Bathrooms</h3>
                                                    <span className="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span>
                                                    <div className="grid grid-cols-2 gap-4 mb-5">
                                                        <div>
                                                            <label htmlFor="minBaths" className="block mb-2">Min Baths</label>
                                                            <input
                                                                type="number"
                                                                id="minBaths"
                                                                name="minBaths"
                                                                value={filters.minBaths}
                                                                onChange={(e) => handleFilterChange('minBaths', parseInt(e.target.value))}
                                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                                            />
                                                        </div>

                                                        <div>
                                                            <label htmlFor="maxBaths" className="block mb-2">Max Baths</label>
                                                            <input
                                                                type="number"
                                                                id="maxBaths"
                                                                name="maxBaths"
                                                                value={filters.maxBaths}
                                                                onChange={(e) => handleFilterChange('maxBaths', parseInt(e.target.value))}
                                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </FilterDropdown>

                                            <FilterDropdown title="Home Type" onApply={handleFilterSubmit}>
                                                <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                                                    <h3 className="font-medium text-black dark:text-white">Home Type</h3>
                                                    <span className="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span>
                                                    <SelectGroupThree label='' selected={filters.propertyType} setSelected={(val) => { handleFilterChange('propertyType', val) }} options={[{ label: 'All', value: '' }, { label: 'House', value: 'House' }, { label: 'Apartment', value: 'Apartment' }, { label: 'Townhouse', value: 'Townhouse' }]} />
                                                </div>
                                            </FilterDropdown>

                                            <FilterDropdown title="Profit" onApply={handleFilterSubmit}>

                                                <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                                                    <h3 className="font-medium text-black dark:text-white">Profit</h3>
                                                    <span className="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span>
                                                    <div className="grid grid-cols-2 gap-4 mb-5">
                                                        <div>
                                                            <label htmlFor="minProfit" className="block mb-2">Min Profit</label>
                                                            <input
                                                                type="number"
                                                                id="minProfit"
                                                                name="minProfit"
                                                                value={filters.minProfit}
                                                                onChange={(e) => handleFilterChange('minProfit', parseInt(e.target.value))}
                                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                                            />
                                                        </div>

                                                        <div>
                                                            <label htmlFor="maxProfit" className="block mb-2">Max Profit</label>
                                                            <input
                                                                type="number"
                                                                id="maxProfit"
                                                                name="maxProfit"
                                                                value={filters.maxProfit}
                                                                onChange={(e) => handleFilterChange('maxProfit', parseInt(e.target.value))}
                                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </FilterDropdown>
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex h-full">
                        <div ref={mapContainer} className="w-3/5 h-full" />

                        <div className="w-2/5 h-full overflow-y-auto p-4">
                            <div className="grid grid-cols-2 gap-4 pb-10">
                                {Object.values(propertiesMap).map((property) => (
                                    <div key={property.id}>
                                        <PropertyCard property={property} showDetails={true} onClick={() => handlePropertyClick(property)} onViewDetails={handleViewDetails} onFavoriteToggle={handleFavoriteClick} />
                                    </div>
                                ))}
                            </div>

                            {!loading && hasMore && (
                                <button
                                    disabled={loading}
                                    onClick={handleLoadMore}
                                    className="mt-0 w-full bg-primary text-white py-2 px-4 rounded hover:bg-blue-600"
                                >
                                    {loading ? 'Loading...' : 'Load More'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
};

export default Properties;