import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserMe } from '../clients/vercasa';
import { userAuthAPI, useUserAPI } from '../hooks/useVerCasaAPI';
import { useApi } from './ApiContext';


interface AuthContextType {
    user: UserMe | null;
    login: (email: string, password: string) => Promise<void>;
    signup: (email: string, password: string) => Promise<void>;
    logout: () => void;
    setUser: (user: UserMe | null) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<UserMe | null>(null);
    const { tokens, setTokens } = useApi();
    const { loginUser, refreshAuth } = userAuthAPI();
    const { me, signupUser } = useUserAPI();


    const login = async (email: string, password: string) => {
        try {
            await loginUser(email, password);
            await me();
        } catch (error) {
            throw error;
        }
    };

    const logout = () => {
        setTokens(null);
        setUser(null);
    };

    const signup = async (email: string, password: string) => {
        try {
            await signupUser(email, password);
            await me();
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        const initializeAuth = async () => {
            if (user !== null) {
                return;
            }

            if (tokens) {
                try {
                    const user: UserMe = await me();
                    setUser(user);
                } catch (error) {
                    try {
                        await refreshAuth(tokens.refreshToken);
                        const user: UserMe = await me();
                        setUser(user);
                    } catch (error) {
                        setTokens(null);
                    }
                }
            }
            
        };

        initializeAuth();
    }), [tokens];

    return (
        <AuthContext.Provider value={{ user, setUser, login, signup, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};