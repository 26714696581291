/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RentalEstimate
 */
export interface RentalEstimate {
    /**
     * 
     * @type {string}
     * @memberof RentalEstimate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RentalEstimate
     */
    floorPlanId: string;
    /**
     * 
     * @type {number}
     * @memberof RentalEstimate
     */
    occupancy: number;
    /**
     * 
     * @type {number}
     * @memberof RentalEstimate
     */
    daysRented: number;
    /**
     * 
     * @type {number}
     * @memberof RentalEstimate
     */
    daysVacant: number;
    /**
     * 
     * @type {number}
     * @memberof RentalEstimate
     */
    averageDailyRate: number;
    /**
     * 
     * @type {number}
     * @memberof RentalEstimate
     */
    grossRevenue: number;
    /**
     * 
     * @type {number}
     * @memberof RentalEstimate
     */
    netRevenue: number;
    /**
     * 
     * @type {number}
     * @memberof RentalEstimate
     */
    overhead: number;
    /**
     * 
     * @type {number}
     * @memberof RentalEstimate
     */
    profit: number;
}

/**
 * Check if a given object implements the RentalEstimate interface.
 */
export function instanceOfRentalEstimate(value: object): value is RentalEstimate {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('floorPlanId' in value) || value['floorPlanId'] === undefined) return false;
    if (!('occupancy' in value) || value['occupancy'] === undefined) return false;
    if (!('daysRented' in value) || value['daysRented'] === undefined) return false;
    if (!('daysVacant' in value) || value['daysVacant'] === undefined) return false;
    if (!('averageDailyRate' in value) || value['averageDailyRate'] === undefined) return false;
    if (!('grossRevenue' in value) || value['grossRevenue'] === undefined) return false;
    if (!('netRevenue' in value) || value['netRevenue'] === undefined) return false;
    if (!('overhead' in value) || value['overhead'] === undefined) return false;
    if (!('profit' in value) || value['profit'] === undefined) return false;
    return true;
}

export function RentalEstimateFromJSON(json: any): RentalEstimate {
    return RentalEstimateFromJSONTyped(json, false);
}

export function RentalEstimateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RentalEstimate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'floorPlanId': json['floor_plan_id'],
        'occupancy': json['occupancy'],
        'daysRented': json['days_rented'],
        'daysVacant': json['days_vacant'],
        'averageDailyRate': json['average_daily_rate'],
        'grossRevenue': json['gross_revenue'],
        'netRevenue': json['net_revenue'],
        'overhead': json['overhead'],
        'profit': json['profit'],
    };
}

export function RentalEstimateToJSON(value?: RentalEstimate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'floor_plan_id': value['floorPlanId'],
        'occupancy': value['occupancy'],
        'days_rented': value['daysRented'],
        'days_vacant': value['daysVacant'],
        'average_daily_rate': value['averageDailyRate'],
        'gross_revenue': value['grossRevenue'],
        'net_revenue': value['netRevenue'],
        'overhead': value['overhead'],
        'profit': value['profit'],
    };
}

