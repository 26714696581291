import * as Sentry from "@sentry/react";
import React from "react";
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from "react-router-dom";
import env from "./config/env";

Sentry.init({
    dsn: env.SENTRY_DSN,
    environment: env.SENTRY_ENVIRONMENT,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
    ],
    profilesSampleRate: env.SENTRY_PROFILES_SAMPLE_RATE || 1.0,
    // Tracing
    tracesSampleRate: env.SENTRY_TRACES_SAMPLE_RATE, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: env.SENTRY_REPLAYS_ERROR_SAMPLE_RATE,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);