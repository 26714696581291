/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PropertySendPlatformMessageRequest
 */
export interface PropertySendPlatformMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertySendPlatformMessageRequest
     */
    message: string;
}

/**
 * Check if a given object implements the PropertySendPlatformMessageRequest interface.
 */
export function instanceOfPropertySendPlatformMessageRequest(value: object): value is PropertySendPlatformMessageRequest {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function PropertySendPlatformMessageRequestFromJSON(json: any): PropertySendPlatformMessageRequest {
    return PropertySendPlatformMessageRequestFromJSONTyped(json, false);
}

export function PropertySendPlatformMessageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertySendPlatformMessageRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
    };
}

export function PropertySendPlatformMessageRequestToJSON(value?: PropertySendPlatformMessageRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
    };
}

