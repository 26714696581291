import React, { useEffect, useState } from 'react';
import { PropertyList } from '../../clients/vercasa';
import { usePropertyListsAPI } from '../../hooks/useVerCasaAPI';
import ModalOne from './ModalOne';

interface AddToListModalProps {
    isOpen: boolean;
    onClose: () => void;
    propertyId: string;
}

const AddToListModal: React.FC<AddToListModalProps> = ({ isOpen, onClose, propertyId }) => {
    const { getLists, createList, addPropertyToList } = usePropertyListsAPI();
    const [lists, setLists] = useState<PropertyList[]>([]);
    const [selectedListId, setSelectedListId] = useState<string>('');
    const [isCreatingNewList, setIsCreatingNewList] = useState(false);
    const [newListName, setNewListName] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            fetchLists();
        }
    }, [isOpen]);

    const fetchLists = async () => {
        try {
            const response = await getLists({});
            setLists(response.data);
        } catch (error) {
            console.error('Error fetching lists:', error);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (isCreatingNewList) {
                const newList = await createList(newListName);
                await addPropertyToList(newList.id, propertyId);
            } else {
                await addPropertyToList(selectedListId, propertyId);
            }
            onClose();
        } catch (error) {
            console.error('Error adding property to list:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ModalOne isOpen={isOpen} onClose={onClose} title="Add to Property List">
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                        Select a list
                    </label>
                    <select
                        value={selectedListId}
                        onChange={(e) => setSelectedListId(e.target.value)}
                        disabled={isCreatingNewList}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    >
                        <option value="">Select a list</option>
                        {lists.map((list) => (
                            <option key={list.id} value={list.id}>
                                {list.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-4">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={isCreatingNewList}
                            onChange={() => setIsCreatingNewList(!isCreatingNewList)}
                            className="form-checkbox"
                        />
                        <span className="ml-2">Create a new list</span>
                    </label>
                </div>

                {isCreatingNewList && (
                    <div className="mb-4">
                        <label className="mb-2.5 block font-medium text-black dark:text-white">
                            New List Name
                        </label>
                        <input
                            type="text"
                            value={newListName}
                            onChange={(e) => setNewListName(e.target.value)}
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            required
                        />
                    </div>
                )}

                <div className="flex justify-end gap-4.5">
                    <button
                        type="button"
                        onClick={onClose}
                        className="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={loading || (!isCreatingNewList && !selectedListId)}
                        className="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90">
                        {loading ? 'Adding...' : 'Add to List'}
                    </button>
                </div>
            </form>
        </ModalOne>
    );
};

export default AddToListModal;