import { ChevronDown, CirclePlus, Heart, HeartCrack, Send } from 'lucide-react';
import React, { useState } from 'react';
import { Property } from '../../clients/vercasa';
import useOutsideClick from '../../hooks/useOutsideClick';

interface PropertyDetailActionDropdownProps {
    property: Property;
    onAddToList: () => void;
    onFavorite: () => void;
}

const PropertyDetailActionDropdown: React.FC<PropertyDetailActionDropdownProps> = ({ property, onAddToList, onFavorite }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useOutsideClick(() => setIsOpen(false));
    const toggleDropdown = () => setIsOpen(!isOpen);

    return (

        <div ref={ref} className="relative text-right">
            <button
                onClick={toggleDropdown}
                className="inline-flex items-center gap-2.5 rounded-md bg-primary py-3 px-5.5 font-medium text-white hover:bg-opacity-90">
                Actions
                <ChevronDown className={`transition duration-200 ease-linear ${isOpen && 'rotate-180'}`} />
            </button>

            {isOpen &&
                <div className="absolute left-0 top-full z-40 mt-2 w-full rounded-md border border-stroke bg-white py-3 shadow-card dark:border-strokedark dark:bg-boxdark">
                    <ul className="flex flex-col">
                        <li className='hover:bg-whiter dark:hover:bg-meta-4'>
                            <button onClick={onFavorite} className="flex py-2 px-5 font-medium">
                                {property.favorite
                                    ? <HeartCrack size={18} className='inline mr-2 text-danger' />
                                    : <Heart size={18} className='inline mr-2 text-danger fill-current' />
                                }
                                {property.favorite ? 'Unfavorite' : 'Favorite'}
                            </button>
                        </li>
                        <li className='hover:bg-whiter dark:hover:bg-meta-4'>
                            <button className="flex py-2 px-5 font-medium">
                                <Send size={18} className='inline mr-2' /> Send Message
                            </button>
                        </li>
                        <li className='hover:bg-whiter dark:hover:bg-meta-4'>
                            <button className="flex py-2 px-5 font-medium" onClick={onAddToList}>
                                <CirclePlus size={18} className='inline mr-2' /> Add to List
                            </button>
                        </li>
                    </ul>
                </div>


            }
        </div>
    );
};

export default PropertyDetailActionDropdown;