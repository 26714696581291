import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface BreadcrumbItem {
  label: string;
  path: string;
}

interface BreadcrumbProps {
  items?: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items = [] }) => {
  const location = useLocation();
  const [title, setTitle] = useState('');
  const currentPath = location.pathname;


  useEffect(() => {
    setTitle(document.title);
  }, [currentPath]);

  // Generate breadcrumb items based on the current path if not provided
  const breadcrumbItems = items.length > 0 ? items : generateBreadcrumbItems(currentPath);

  return (
    <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
      <h2 className="text-title-md2 font-semibold text-black dark:text-white">
        {title}
      </h2>

      <nav>
        <ol className="flex items-center gap-2">
          <li>
            <Link className="font-medium" to="/">
              Dashboard
            </Link>
          </li>
          {breadcrumbItems.map((item, index) => (
            <li key={item.path}>
              {index < breadcrumbItems.length - 1 ? (
                <>
                  <span className="mx-2">/</span>
                  <Link className="font-medium" to={item.path}>
                    {item.label}
                  </Link>
                </>
              ) : (
                <>
                  <span className="mx-2">/</span>
                  <span className="font-medium text-primary">{document.title}</span>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

const generateBreadcrumbItems = (path: string): BreadcrumbItem[] => {
  const pathSegments = path.split('/').filter(Boolean);
  return pathSegments.map((segment, index) => ({
    label: segment.charAt(0).toUpperCase() + segment.slice(1),
    path: '/' + pathSegments.slice(0, index + 1).join('/')
  }));
};

export default Breadcrumb;