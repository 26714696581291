import { Bath, Bed, CalendarDays, DollarSign, Heart, Maximize } from 'lucide-react';
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { FloorPlan, Property } from '../../clients/vercasa';
import { formatCurrency } from '../../utils/currencyFormatter';
import { getAverageOccupancyFromFloorPlan, getFloorPlanRange, getHighestAverageDailyRateFromFloorPlan, getHighestProfitFromFloorPlan, getMostProfitableFloorPlan, getProfitColor } from '../../utils/propertyUtils';


interface PropertyCardProps {
    property: Property;
    showDetails?: boolean;
    onClick: () => void;
    onViewDetails: (propertyId: string) => void;
    onFavoriteToggle: (propertyId: string) => Promise<void>;
}


const PropertyCard: React.FC<PropertyCardProps> = ({ property, showDetails, onClick, onViewDetails, onFavoriteToggle }) => {
    const [isFavorite, setIsFavorite] = useState<boolean>(property.favorite);

    const address = property.address;
    const mostProfitableFloorPlan: FloorPlan = getMostProfitableFloorPlan(property);
    const highestAverageDailyRate = getHighestAverageDailyRateFromFloorPlan(mostProfitableFloorPlan);
    const highestProfit = getHighestProfitFromFloorPlan(mostProfitableFloorPlan);
    const highestOccupancy = getAverageOccupancyFromFloorPlan(mostProfitableFloorPlan);
    const [minBeds, maxBeds] = getFloorPlanRange(property, 'bedrooms');
    const [minBaths, maxBaths] = getFloorPlanRange(property, 'bathrooms');
    const [minSqft, maxSqft] = getFloorPlanRange(property, 'sqft');
    const profitColor = getProfitColor(highestProfit);


    const handleFavoriteClick = async (e: React.MouseEvent) => {
        e.stopPropagation();

        try {
            await onFavoriteToggle(property.id);
            setIsFavorite(prev => !prev);
        } catch (error) {
            console.error('Error favoriting property:', error);
        }
    };

    return (
        <div onClick={onClick} className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 transform hover:scale-105 cursor-pointer dark:bg-boxdark">

            
            <div className="relative">
                <LazyLoadImage
                    src={property.images[0] || 'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'}
                    alt={property.name || 'Property'}
                    effect="blur"
                    className="h-full w-full object-cover"
                />
                <div className="absolute top-0 left-0 bg-primary text-white px-2 py-1 m-2 rounded">
                    {property.type}
                </div>

                <button
                    onClick={handleFavoriteClick}
                    className="absolute top-0 right-0 m-2 p-1 rounded-full bg-white bg-opacity-50 hover:bg-opacity-100 transition-all duration-300"
                >
                    <Heart
                        size={24}
                        className={`${isFavorite ? 'text-danger fill-current' : 'text-gray-600'} transition-colors duration-300 hover:text-danger`}
                    />
                </button>

                {property.type === "Apartment" &&
                    <div className="absolute bottom-0 right-0 bg-black bg-opacity-50 text-white px-2 py-1 m-2 rounded">
                        {property.floorPlans.length} floor plans
                    </div>
                }
            </div>
            <div className="p-4">

                <h2 className="text-xl text-center font-semibold text-gray-800 dark:text-white mb-3">
                    <p className="text-sm truncate">{address.street}, {address.city}, {address.stateCode}</p>
                </h2>

                {showDetails &&
                    <div className="grid grid-cols-1 gap-2 mb-3" >
                        <div className="flex font-medium items-center">
                            <Bed size={18} className="mr-1 text-primary" />
                            <span className="text-sm">{minBeds === maxBeds ? minBeds : `${minBeds}-${maxBeds}`} Beds</span>
                        </div>
                        <div className="flex items-center">
                            <Bath size={18} className="mr-1 text-primary" />
                            <span className="text-sm">{minBaths === maxBaths ? minBaths : `${minBaths}-${maxBaths}`} Baths</span>
                        </div>
                        <div className="flex items-center">
                            <Maximize size={18} className="mr-1 text-primary" />
                            <span className="text-sm">{minSqft === maxSqft ? minSqft : `${minSqft}-${maxSqft}`} sqft</span>
                        </div>
                        <div className="flex items-center">
                            <DollarSign size={18} className="mr-1 text-primary" />
                            <span className="text-sm">${Math.min(...property.floorPlans.map(fp => fp.listedPrice)).toLocaleString()} - ${Math.max(...property.floorPlans.map(fp => fp.listedPrice)).toLocaleString()} Rent</span>
                        </div>
                    </div>
                }

                <div className="mt-2 pt-2 border-t border-gray-200 dark:border-gray-700">
                    <div className="flex font-medium justify-between items-center">

                        <div className="text-lg dark:text-white">
                            <span>
                                <CalendarDays size={16} className="mr-1 inline" />{highestOccupancy}%
                            </span>
                            <span className="text-xs text-center block">Occupancy</span>
                        </div>

                        <div className="text-lg dark:text-white">
                            <span>
                                {formatCurrency(highestAverageDailyRate)}
                            </span>
                            <span className="text-xs text-center block">Daily Rate</span>
                        </div>

                        <div className={`text-lg ${profitColor}`}>
                            {formatCurrency(highestProfit)}
                            <span className="text-xs text-center block text-gray-500">Profit</span>
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <button onClick={() => onViewDetails(property.id)} className="w-full bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark">
                        View Details
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PropertyCard;