const env = {
  VERCASA_API_URL: import.meta.env.VITE_VERCASA_API_URL || 'http://localhost:8000',
  MAPBOX_ACCESS_TOKEN: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,

  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  SENTRY_AUTH_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
  SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT || "local",
  SENTRY_PROFILES_SAMPLE_RATE: parseFloat(import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE) || 1.0,
  SENTRY_TRACES_SAMPLE_RATE: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) || 1.0,
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE: parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE) || 1.0,
  SENTRY_REPLAYS_ERROR_SAMPLE_RATE: parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_ERROR_SAMPLE_RATE) || 1.0,
};


export default env;