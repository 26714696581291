/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { PropertyPointOfInterest } from './PropertyPointOfInterest';
import {
    PropertyPointOfInterestFromJSON,
    PropertyPointOfInterestFromJSONTyped,
    PropertyPointOfInterestToJSON,
} from './PropertyPointOfInterest';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { PropertyOfficeHours } from './PropertyOfficeHours';
import {
    PropertyOfficeHoursFromJSON,
    PropertyOfficeHoursFromJSONTyped,
    PropertyOfficeHoursToJSON,
} from './PropertyOfficeHours';
import type { FloorPlan } from './FloorPlan';
import {
    FloorPlanFromJSON,
    FloorPlanFromJSONTyped,
    FloorPlanToJSON,
} from './FloorPlan';

/**
 * 
 * @export
 * @interface Property
 */
export interface Property {
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    phoneNumber: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Property
     */
    images: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    special: string;
    /**
     * The type of property
     * @type {string}
     * @memberof Property
     */
    type: PropertyTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Property
     */
    amenities: Array<PropertyAmenitiesEnum>;
    /**
     * The lease terms of the property
     * @type {Array<number>}
     * @memberof Property
     */
    leaseTerms: Array<number>;
    /**
     * 
     * @type {Array<PropertyOfficeHours>}
     * @memberof Property
     */
    officeHours: Array<PropertyOfficeHours>;
    /**
     * 
     * @type {Array<PropertyPointOfInterest>}
     * @memberof Property
     */
    pois: Array<PropertyPointOfInterest>;
    /**
     * 
     * @type {Array<FloorPlan>}
     * @memberof Property
     */
    floorPlans: Array<FloorPlan>;
    /**
     * 
     * @type {Address}
     * @memberof Property
     */
    address: Address;
    /**
     * 
     * @type {Company}
     * @memberof Property
     */
    company: Company;
    /**
     * Indicates whether the property is favorited by the user
     * @type {boolean}
     * @memberof Property
     */
    favorite: boolean;
    /**
     * Indicates whether the user has sent a message to this property
     * @type {boolean}
     * @memberof Property
     */
    sentMessage: boolean;
}


/**
 * @export
 */
export const PropertyTypeEnum = {
    House: 'House',
    Apartment: 'Apartment',
    Townhouse: 'Townhouse',
    Condo: 'Condo'
} as const;
export type PropertyTypeEnum = typeof PropertyTypeEnum[keyof typeof PropertyTypeEnum];

/**
 * @export
 */
export const PropertyAmenitiesEnum = {
    Pool: 'Pool',
    FitnessCenter: 'Fitness Center',
    Elevator: 'Elevator',
    Clubhouse: 'Clubhouse',
    ControlledAccess: 'Controlled Access',
    Recycling: 'Recycling',
    BusinessCenter: 'Business Center',
    Grill: 'Grill'
} as const;
export type PropertyAmenitiesEnum = typeof PropertyAmenitiesEnum[keyof typeof PropertyAmenitiesEnum];


/**
 * Check if a given object implements the Property interface.
 */
export function instanceOfProperty(value: object): value is Property {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('images' in value) || value['images'] === undefined) return false;
    if (!('special' in value) || value['special'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('amenities' in value) || value['amenities'] === undefined) return false;
    if (!('leaseTerms' in value) || value['leaseTerms'] === undefined) return false;
    if (!('officeHours' in value) || value['officeHours'] === undefined) return false;
    if (!('pois' in value) || value['pois'] === undefined) return false;
    if (!('floorPlans' in value) || value['floorPlans'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('favorite' in value) || value['favorite'] === undefined) return false;
    if (!('sentMessage' in value) || value['sentMessage'] === undefined) return false;
    return true;
}

export function PropertyFromJSON(json: any): Property {
    return PropertyFromJSONTyped(json, false);
}

export function PropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Property {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'phoneNumber': json['phone_number'],
        'images': json['images'],
        'special': json['special'],
        'type': json['type'],
        'amenities': json['amenities'],
        'leaseTerms': json['lease_terms'],
        'officeHours': ((json['office_hours'] as Array<any>).map(PropertyOfficeHoursFromJSON)),
        'pois': ((json['pois'] as Array<any>).map(PropertyPointOfInterestFromJSON)),
        'floorPlans': ((json['floor_plans'] as Array<any>).map(FloorPlanFromJSON)),
        'address': AddressFromJSON(json['address']),
        'company': CompanyFromJSON(json['company']),
        'favorite': json['favorite'],
        'sentMessage': json['sent_message'],
    };
}

export function PropertyToJSON(value?: Property | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'phone_number': value['phoneNumber'],
        'images': value['images'],
        'special': value['special'],
        'type': value['type'],
        'amenities': value['amenities'],
        'lease_terms': value['leaseTerms'],
        'office_hours': ((value['officeHours'] as Array<any>).map(PropertyOfficeHoursToJSON)),
        'pois': ((value['pois'] as Array<any>).map(PropertyPointOfInterestToJSON)),
        'floor_plans': ((value['floorPlans'] as Array<any>).map(FloorPlanToJSON)),
        'address': AddressToJSON(value['address']),
        'company': CompanyToJSON(value['company']),
        'favorite': value['favorite'],
        'sent_message': value['sentMessage'],
    };
}

