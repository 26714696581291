/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    formatted: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    stateCode: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    countryCode: string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    longitude: number;
    /**
     * Google Place ID
     * @type {string}
     * @memberof Address
     */
    placeId: string;
}

/**
 * Check if a given object implements the Address interface.
 */
export function instanceOfAddress(value: object): value is Address {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('formatted' in value) || value['formatted'] === undefined) return false;
    if (!('street' in value) || value['street'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('postalCode' in value) || value['postalCode'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('stateCode' in value) || value['stateCode'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('countryCode' in value) || value['countryCode'] === undefined) return false;
    if (!('latitude' in value) || value['latitude'] === undefined) return false;
    if (!('longitude' in value) || value['longitude'] === undefined) return false;
    if (!('placeId' in value) || value['placeId'] === undefined) return false;
    return true;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'formatted': json['formatted'],
        'street': json['street'],
        'city': json['city'],
        'postalCode': json['postal_code'],
        'state': json['state'],
        'stateCode': json['state_code'],
        'country': json['country'],
        'countryCode': json['country_code'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'placeId': json['place_id'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'formatted': value['formatted'],
        'street': value['street'],
        'city': value['city'],
        'postal_code': value['postalCode'],
        'state': value['state'],
        'state_code': value['stateCode'],
        'country': value['country'],
        'country_code': value['countryCode'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
        'place_id': value['placeId'],
    };
}

