// src/utils/currencyFormatter.ts

/**
 * Formats an integer into a currency string (USD).
 * @param value - The integer value to format
 * @returns A formatted string representing the value in USD without cents
 */
export const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(value);
};