/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMeResponse
 */
export interface UserMeResponse {
    /**
     * 
     * @type {string}
     * @memberof UserMeResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserMeResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserMeResponse
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserMeResponse
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserMeResponse
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UserMeResponse
     */
    photo: string;
}

/**
 * Check if a given object implements the UserMeResponse interface.
 */
export function instanceOfUserMeResponse(value: object): value is UserMeResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('photo' in value) || value['photo'] === undefined) return false;
    return true;
}

export function UserMeResponseFromJSON(json: any): UserMeResponse {
    return UserMeResponseFromJSONTyped(json, false);
}

export function UserMeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'phoneNumber': json['phone_number'],
        'photo': json['photo'],
    };
}

export function UserMeResponseToJSON(value?: UserMeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'phone_number': value['phoneNumber'],
        'photo': value['photo'],
    };
}

