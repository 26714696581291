import { ArrowLeft } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import Illustration from '../images/illustration/illustration-01.svg';
import DefaultLayout from '../layout/DefaultLayout';

const ErrorPage: React.FC = () => {
  return (
    <DefaultLayout>
      <Breadcrumb pageName="Error Page" />

      <div className="rounded-sm border border-stroke bg-white px-5 py-10 shadow-default dark:border-strokedark dark:bg-boxdark sm:py-20">
        <div className="mx-auto max-w-[410px]">
          <img src={Illustration} alt="illustration" />

          <div className="mt-7.5 text-center">
            <h2 className="mb-3 text-2xl font-bold text-black dark:text-white">
              Sorry, the page can’t be found
            </h2>
            
            <p className="font-medium">
              The page you were looking for appears to have been moved, deleted
              or does not exist.
            </p>

            <Link
              to="/"
              className="mt-7.5 inline-flex items-center gap-2 rounded-md bg-primary py-3 px-6 font-medium text-white hover:bg-opacity-90"
            >
              <ArrowLeft />
              <span>Back to Home</span>
            </Link>

          </div>
        </div>
      </div>
    </DefaultLayout >
  );
};

export default ErrorPage;
