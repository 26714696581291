import { ApexOptions } from 'apexcharts';
import { Info } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { FloorPlan } from '../../clients/vercasa';
import Popover from '../Popovers/Popover';

interface FloorPlanROIChartProps {
    floorPlan: FloorPlan;
}

const FloorPlanROIChart: React.FC<FloorPlanROIChartProps> = ({ floorPlan }) => {
    const [state, setState] = useState({
        series: [
            {
                name: 'Return on Investment',
                data: [],
            },
        ],
    });

    const [breakEvenPoint, setBreakEvenPoint] = useState<{ x: number; y: number } | null>(null);

    useEffect(() => {
        const calculateROI = () => {
            const initialInvestment = calculateInitialInvestment(floorPlan);
            const monthlyProfit = floorPlan.rentalEstimates[0].profit / 12;
            const roiData = [];

            let currentDate = new Date();
            currentDate.setDate(1); // Set to the first day of the current month
            currentDate.setHours(0, 0, 0, 0); // Set time to midnight

            let cumulativeReturn = -initialInvestment;
            let breakEvenFound = false;

            for (let i = 0; i < 12; i++) {
                if (cumulativeReturn >= 0 && !breakEvenFound) {
                    setBreakEvenPoint({ x: currentDate.getTime(), y: cumulativeReturn });
                    breakEvenFound = true;
                }
                roiData.push([currentDate.getTime(), parseFloat(cumulativeReturn.toFixed(2))]);
                cumulativeReturn += monthlyProfit;
                currentDate.setMonth(currentDate.getMonth() + 1);
            }

            setState({
                series: [
                    {
                        name: 'Return on Investment',
                        data: roiData,
                    },
                ],
            });
        };

        calculateROI();
    }, [floorPlan]);

    const calculateInitialInvestment = (floorPlan: FloorPlan) => {
        const furnishingCost = (floorPlan.bedrooms * 3000) + (floorPlan.bathrooms * 1250) + 4000;
        const initialRent = floorPlan.listedPrice * 2;
        return furnishingCost + initialRent;
    };

    const options: ApexOptions = {
        chart: {
            fontFamily: 'Satoshi, sans-serif',
            height: 350,
            type: 'area',
            toolbar: {
                show: false,
            },
        },
        colors: ['#3C50E0'],
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100],
                colorStops: [
                    {
                        offset: 0,
                        color: '#3C50E0',
                        opacity: 0.8
                    },
                    {
                        offset: 100,
                        color: '#3C50E0',
                        opacity: 0.1
                    },
                ]
            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: 3,
        },
        xaxis: {
            type: 'datetime',
            categories: Array.from({ length: 12 }, (_, i) => {
                const date = new Date();
                date.setMonth(date.getMonth() + i);
                return date;
            }),
            labels: {
                rotate: 0,
                format: 'MMM',
            },
            tickAmount: 11,
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: true,
            },
        },
        yaxis: {
            title: {
                text: 'Return on Investment ($)',
            },
            labels: {
                formatter: function (value) {
                    return value.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    });
                }
            }
        },
        tooltip: {
            enabled: true,
            followCursor: true,
            x: {
                format: 'MMM',
            },
            y: {
                formatter: function (value) {
                    return value.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    });
                }
            }
        },
        grid: {
            strokeDashArray: 7,
        },
        annotations: breakEvenPoint ? {
            points: [
                {
                    x: breakEvenPoint.x,
                    y: breakEvenPoint.y,
                    marker: {
                        size: 10,
                        fillColor: '#00E396',
                        strokeColor: '#fff',
                        strokeWidth: 2,
                        cssClass: 'bg-primary',
                    },
                    label: {
                        // borderColor: '#775DD0',
                        offsetY: -20,
                        style: {
                            fontSize: '1rem',
                            background: '#775DD0',
                            cssClass: '!fill-white font-md font-medium',
                            padding: {
                                top: 7,
                                bottom: 7,
                                left: 7,
                                right: 7,
                            }
                        },
                        text: 'Break Even',
                    }
                }
            ]
        } : undefined,
    };

    const initialInvestment = calculateInitialInvestment(floorPlan);
    const monthlyProfit = floorPlan.rentalEstimates[0].profit / 12;
    const breakEvenMonths = Math.ceil(initialInvestment / monthlyProfit);

    return (
        <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pb-5 pt-7.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
            <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap mb-6">
                <div className="flex w-full flex-wrap gap-3 sm:gap-5">
                    <div className="flex min-w-47.5">

                        <span className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-meta-1">
                            <span className="block h-2.5 w-full max-w-2.5 rounded-full bg-meta-1"></span>
                        </span>

                        <div className="group relative cursor-help">
                            <div className="w-full">
                                <p className="font-semibold text-meta-1">Initial Investment</p>
                                <p className="text-md font-medium">
                                    ${initialInvestment.toLocaleString()}
                                </p>
                            </div>
                        </div>

                        <Popover
                            trigger={<Info className="inline-block ml-2 cursor-help" size={16} />}
                            title="Initial Investment"
                            content={`The initial cost to invest in this property.

                                    This calculation is based on the following:
                                    • 2 months rent upfront for signing the lease.
                                    • $3,000 per bedroom in furnishing costs.
                                    • $1,250 per bathroom in furnishing costs.
                                    • $3,000 per living room in furnishing costs.
                                    • $1,000 per kitchen in.
                                `}
                            position="right"
                        />

                    </div>
                    <div className="flex min-w-47.5">
                        <span className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-meta-3">
                            <span className="block h-2.5 w-full max-w-2.5 rounded-full bg-meta-3"></span>
                        </span>
                        <div className="w-full">
                            <p className="font-semibold text-meta-3">Monthly Profit</p>
                            <p className="text-md font-medium">${monthlyProfit.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                        </div>
                    </div>
                    <div className="flex min-w-47.5">
                        <span className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-meta-6">
                            <span className="block h-2.5 w-full max-w-2.5 rounded-full bg-meta-6"></span>
                        </span>
                        <div className="w-full">
                            <p className="font-semibold text-meta-6">Break Even</p>
                            <p className="text-md font-medium">{breakEvenMonths} months</p>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div id="chartOne" className="-ml-5 h-[355px] w-[105%]">
                    <ReactApexChart
                        options={options}
                        series={state.series}
                        type="area"
                        height={350}
                    />
                </div>
            </div>
        </div>
    );
};

export default FloorPlanROIChart;