/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PropertyListResponse } from './PropertyListResponse';
import {
    PropertyListResponseFromJSON,
    PropertyListResponseFromJSONTyped,
    PropertyListResponseToJSON,
} from './PropertyListResponse';

/**
 * 
 * @export
 * @interface PaginatedPropertyListsResponse
 */
export interface PaginatedPropertyListsResponse {
    /**
     * 
     * @type {Array<PropertyListResponse>}
     * @memberof PaginatedPropertyListsResponse
     */
    data: Array<PropertyListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedPropertyListsResponse
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedPropertyListsResponse
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedPropertyListsResponse
     */
    offset: number;
}

/**
 * Check if a given object implements the PaginatedPropertyListsResponse interface.
 */
export function instanceOfPaginatedPropertyListsResponse(value: object): value is PaginatedPropertyListsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('limit' in value) || value['limit'] === undefined) return false;
    if (!('offset' in value) || value['offset'] === undefined) return false;
    return true;
}

export function PaginatedPropertyListsResponseFromJSON(json: any): PaginatedPropertyListsResponse {
    return PaginatedPropertyListsResponseFromJSONTyped(json, false);
}

export function PaginatedPropertyListsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedPropertyListsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(PropertyListResponseFromJSON)),
        'count': json['count'],
        'limit': json['limit'],
        'offset': json['offset'],
    };
}

export function PaginatedPropertyListsResponseToJSON(value?: PaginatedPropertyListsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(PropertyListResponseToJSON)),
        'count': value['count'],
        'limit': value['limit'],
        'offset': value['offset'],
    };
}

