import * as Sentry from "@sentry/react";
import { Route } from 'react-router-dom';
import Loader from './common/Loader';
import { ApiProvider } from './contexts/ApiContext';
import { AuthProvider } from './contexts/AuthContext';
import { ColorModeProvider } from './contexts/ColorModeContext';
import { LoadingProvider } from './contexts/LoadingContext';
import ResetPassword from './pages/Authentication/ResetPassword';
import SignIn from './pages/Authentication/SignIn';
import SignUp from './pages/Authentication/SignUp';
import ECommerce from './pages/Dashboard/ECommerce';
import ErrorPage from './pages/ErrorPage';
import Properties from './pages/Properties';
import PropertyDetail from './pages/PropertyDetail';
import PropertyLists from './pages/PropertyLists';
import Settings from './pages/Settings';
import ProtectedRoute from './routes/ProtectedRoutes';
import PublicRoute from './routes/PublicRoutes';
import { SentryRoutes } from './sentry';


function App() {
  return (
    <LoadingProvider>
      <ColorModeProvider>
        <ApiProvider>
          <AuthProvider>
            <Loader />
            <SentryRoutes>
              {/* Public routes */}
              <Route element={<PublicRoute />}>
                <Route path="/auth/signin" element={<SignIn />} />
                <Route path="/auth/signup" element={<SignUp />} />
                <Route path="/auth/forgot-password" element={<ResetPassword />} />
              </Route>

              {/* Protected routes */}
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<ECommerce />} />
                <Route path="/properties" element={<Properties />} />
                <Route path="/properties/:id" element={<PropertyDetail />} />
                <Route path="/user/settings" element={<Settings />} />
                <Route path="/property-lists" element={<PropertyLists />} />
                {/* Add your other protected routes here */}
              </Route>

              {/* Fallback route for 404 */}
              <Route path="*" element={<ErrorPage />} />
            </SentryRoutes>
          </AuthProvider>
        </ApiProvider>
      </ColorModeProvider>
    </LoadingProvider>
  );
}

export default Sentry.withProfiler(App);