/* tslint:disable */
/* eslint-disable */
/**
 * VerCasa API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface PropertyPointOfInterest
 */
export interface PropertyPointOfInterest {
    /**
     * 
     * @type {string}
     * @memberof PropertyPointOfInterest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyPointOfInterest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyPointOfInterest
     */
    type: PropertyPointOfInterestTypeEnum;
    /**
     * 
     * @type {Address}
     * @memberof PropertyPointOfInterest
     */
    address: Address;
    /**
     * 
     * @type {number}
     * @memberof PropertyPointOfInterest
     */
    distance: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyPointOfInterest
     */
    drive: number;
}


/**
 * @export
 */
export const PropertyPointOfInterestTypeEnum = {
    Education: 'Education',
    Shopping: 'Shopping',
    Transportation: 'Transportation'
} as const;
export type PropertyPointOfInterestTypeEnum = typeof PropertyPointOfInterestTypeEnum[keyof typeof PropertyPointOfInterestTypeEnum];


/**
 * Check if a given object implements the PropertyPointOfInterest interface.
 */
export function instanceOfPropertyPointOfInterest(value: object): value is PropertyPointOfInterest {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('distance' in value) || value['distance'] === undefined) return false;
    if (!('drive' in value) || value['drive'] === undefined) return false;
    return true;
}

export function PropertyPointOfInterestFromJSON(json: any): PropertyPointOfInterest {
    return PropertyPointOfInterestFromJSONTyped(json, false);
}

export function PropertyPointOfInterestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyPointOfInterest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'address': AddressFromJSON(json['address']),
        'distance': json['distance'],
        'drive': json['drive'],
    };
}

export function PropertyPointOfInterestToJSON(value?: PropertyPointOfInterest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'type': value['type'],
        'address': AddressToJSON(value['address']),
        'distance': value['distance'],
        'drive': value['drive'],
    };
}

